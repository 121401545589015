import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./Custom.css";

import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "./components/Home";
import Deals from "./components/Deals";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Logout from "./components/Logout";
import Dashboard from "./components/Dashboard";
import Protected from "./components/Protected";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Blogs from "./components/Blogs";
import Single_blog from "./components/Single_blog";
import Flights from "./components/Flights";
import Filters from "./components/Filters";
import Checkout from "./components/Checkout";
import Bookings from "./components/Bookings";
import Usercards from "./components/Usercards";
import Addcard from "./components/Addcard";
import Updatecard from "./components/Updatecard";
import Wishlist from "./components/Wishlist";
import Ogulecard from "./components/Ogulecard";
import Complete from "./components/Complete";
import Cancel from "./components/Cancel";
import OnwayDate from "./components/OnwayDate";
import RoundTripDate from "./components/RoundTripDate";
import Help from "./components/Help";
import Thanku from "./components/Thanku";
import FaildPayment from "./components/FaildPayment";

import Fromflights from "./components/Fromflights";
import Toflights from "./components/Toflights";
import Updateprofile from "./components/Updateprofile";

import About from "./components/About";
import Privacy_Policy from "./components/Privacy_Policy";
import Our_Team from "./components/Our_Team";
import Guest_Support from "./components/Guest_Support";
import Terms_Conditions from "./components/Terms_Conditions";
import CustomerData from "./components/CustomerData";
import CheckoutDetails from "./components/CheckoutDetails";
import ScrollToTop from "./components/ScrollToTop";
import SuccessfullyBooked from "./components/SuccessfullyBooked";
import FlightsUpdate from "./components/Flights/FlightsUpdate";
import NewHome from "./components/NewHome";
import CheckoutNew from "./components/CheckoutPage/CheckoutNew";
import Campaign from "./components/Campaign";
import FooterWrapper from "./components/FooterWrapper";

function App() {
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const fetchedIp = data.ip;
        sessionStorage.setItem('ipAddress', fetchedIp);
      } catch (error) {
        console.error('Error fetching the public IP address:', error);
      }
    };

    fetchIP();
  }, []);

  sessionStorage.setItem("call", true);
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Route path="/deals">
          <Deals />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/privacy_policy">
          <Privacy_Policy />
        </Route>
        <Route path="/our_team">
          <Our_Team />
        </Route>
        <Route path="/guest_support">
          <Guest_Support />
        </Route>
        <Route path="/terms_conditions">
          <Terms_Conditions />
        </Route>
        <Route path="/blogs" render={(props) => <Blogs {...props} />}></Route>
        <Route path="/single_blog">
          <Single_blog />
        </Route>
        <Route
          path="/flights"
          render={(props) => <FlightsUpdate {...props} />}
        ></Route>
        <Route
          path="/booked_successfully"
          render={(props) => <SuccessfullyBooked {...props} />}
        ></Route>
        <Route
          path="/filters"
          render={(props) => <Filters {...props} />}
        ></Route>
        <Route path="/signup" render={(props) => <Signup {...props} />}></Route>
        <Route path="/logout">
          <Logout />
        </Route>
        <Route path="/checkoutnew">
          <CheckoutNew />
        </Route>
        <Route path="/login" render={(props) => <Login {...props} />}></Route>
        <Route path="/header" render={(props) => <Header {...props} />}></Route>
        {/* <Route path="/footer" render={(props) => <Footer {...props} />}></Route> */}
        <Route
          exact
          path="/"
          render={(props) => <NewHome {...props} />}
        ></Route>

        <Protected path="/dashboard" component={Dashboard} />
        <Protected path="/checkout" component={Checkout} />

        <Protected path="/bookings" component={Bookings} />
        <Protected path="/usercards" component={Usercards} />
        <Protected path="/addcard" component={Addcard} />
        <Protected path="/updatecard" component={Updatecard} />
        <Protected path="/wishlist" component={Wishlist} />
        <Protected path="/ogulecard" component={Ogulecard} />

        <Protected path="/complete" component={Complete} />
        <Protected path="/cancel" component={Cancel} />
        <Protected path="/updateprofile" component={Updateprofile} />

        <Route
          path="/toflights"
          render={(props) => <Toflights {...props} />}
        ></Route>
        <Route
          path="/fromflights"
          render={(props) => <Fromflights {...props} />}
        ></Route>
        <Route
          path="/onwayDate"
          render={(props) => <OnwayDate {...props} />}
        ></Route>
        <Route
          path="/roundTripDate"
          render={(props) => <RoundTripDate {...props} />}
        ></Route>
        <Route path="/help" render={(props) => <Help {...props} />}></Route>
        <Route path="/thanku" render={(props) => <Thanku {...props} />}></Route>
        <Route
          path="/customerdata"
          render={(props) => <CustomerData {...props} />}
        ></Route>
        <Route path="/campaign">
          <Campaign />
        </Route>

        {/* <Route
          path="/help"
          render={(props) => (
            <FooterWrapper path="/help">
              <Help {...props} />
            </FooterWrapper>
          )}
        ></Route> */}
        {/* <Footer /> */}
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </Router>
    </div>
  );
}

export default App;
