import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Toast } from "react-bootstrap";
import OTPBox from "../OTPBox/OTPBox";
import ErrorMessage from "../Error/ErrorMessage";

const ForgotPassword = ({
  firstStep,
  setfirstStep,
  setForgotPassword,
  isLogin,
}) => {
  const history = useHistory();
  const [secondStep, setSecondStep] = useState(false);
  const [thirdStep, setThirdStep] = useState(false);
  const [otpString, setOtpString] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const handleReset = () => {
    setLoader(false);
    setErrorMessage("");
    setEmail("");
    setOtpString("");
    setOtp(new Array(6).fill(""));
    setPassword("");
    setConfirmPassword("");
    setThirdStep(false);
    setForgotPassword(false);
  };

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const completeFirstStep = () => {
    setfirstStep(false);
    setSecondStep(true);
    setThirdStep(false);
  };

  const completeSecondStep = () => {
    setfirstStep(false);
    setSecondStep(false);
    setThirdStep(true);
  };

  const completeThirdStep = () => {
    setfirstStep(false);
    setSecondStep(false);
    setThirdStep(false);
    handleCloseForgotPassword();
  };

  // Verify Email
  const handleEmailVerify = async (e) => {
    e.preventDefault();
    setLoader(true);
    setErrorMessage("");
    try {
      const response = await fetch(
        "https://api.ogule.com/api/auth/send-otp-forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      const res = await response.json();
      if (res.success) {
        setLoader(false);
        completeFirstStep();
      } else {
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  // OTP Verify
  const handleOtpVerify = async (e) => {
    e.preventDefault();
    setLoader(true);
    setErrorMessage("");
    try {
      const response = await fetch(
        "https://api.ogule.com/api/auth/validate-otp-forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ otp: otpString }),
        }
      );
      const res = await response.json();
      if (res.success) {
        setLoader(false);
        alert("OTP verified successfully");
        completeSecondStep();
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  // Password Change
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoader(true);
    setErrorMessage("");
    if (password !== confirmPassword) {
      setLoader(false);
      setErrorMessage("Password and confirm password must be the same.");
    } else {
      try {
        const response = await fetch(
          "https://api.ogule.com/api/auth/reset-password",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ password, otp: otpString }),
          }
        );
        const res = await response.json();
        if (res.success) {
          setLoader(false);
          alert("Password changed successfully");
          completeThirdStep();
        }
      } catch (error) {
        setLoader(false);
        console.error(error);
      }
    }
  };

  const handleCloseForgotPassword = () => {
    history.push("/");
    setForgotPassword(false);
  };

  const handleShowForgotPassword = () => {
    setForgotPassword(true);
  };

  return (
    <div>
      <Modal
        backdrop="static"
        show={handleShowForgotPassword}
        onHide={handleCloseForgotPassword}
        className="login_popup"
      >
        <Modal.Header closeButton>
          <h5 className="text-capitalize mb-2">Forgot Password</h5>
        </Modal.Header>
        <Modal.Body style={{ paddingInline: "2rem" }}>
          <form
            onSubmit={(e) => {
              if (firstStep) {
                handleEmailVerify(e);
              } else if (secondStep) {
                handleOtpVerify(e);
              } else if (thirdStep) {
                handleResetPassword(e);
              }
            }}
          >
            <div className="row gx-3 px-2 mb-2">
              {firstStep && (
                <div className="col-sm-12 mt-3">
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      required
                      disabled={isLogin}
                    />
                  </div>
                </div>
              )}
              {secondStep && (
                <OTPBox
                  otp={otp}
                  otpString={otpString}
                  errorMessage={errorMessage}
                  loader={loader}
                  handleOtpVerify={"handleOtpVerify"}
                  setOtpString={setOtpString}
                  setOtp={setOtp}
                  description="Please enter the verification code we sent to your email address"
                  email={email}
                />
              )}
              {thirdStep && (
                <>
                  <div className="col-sm-12 mt-3">
                    <div className="form-group">
                      <label>Password</label>
                      <div className="input-group">
                        <input
                          type={isShowPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter new Password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={handleShowPassword}
                          >
                            {isShowPassword ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-eye-slash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.88 12.5 8 12.5a6 6 0 0 0 2.79-.588l.77.77A7 7 0 0 1 8 13.5c-5 0-8-5.5-8-5.5S1.94 4.28 3.641 2.762L1.454.574l.708-.708 13 13-.707.708-2.096-2.096z" />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-eye"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                              </svg>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-3">
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <div className="input-group">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter confirm Password"
                          required
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            {showConfirmPassword ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-eye-slash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.88 12.5 8 12.5a6 6 0 0 0 2.79-.588l.77.77A7 7 0 0 1 8 13.5c-5 0-8-5.5-8-5.5S1.94 4.28 3.641 2.762L1.454.574l.708-.708 13 13-.707.708-2.096-2.096z" />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-eye"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                              </svg>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* <ErrorMessage message={errorMessage} /> */}
              <div className="col-12 text-center my-4">
                {firstStep && (
                  <button
                    className="btn btn-primary px-4"
                    type="submit"
                    disabled={loader}
                  >
                    {loader ? "Loading..." : "Verify Email"}
                  </button>
                )}
                {secondStep && (
                  <button
                    className="btn btn-primary px-4"
                    type="submit"
                    disabled={loader}
                  >
                    {loader ? "Loading..." : "Verify OTP"}
                  </button>
                )}
                {thirdStep && (
                  <button
                    className="btn btn-primary px-4"
                    type="submit"
                    disabled={loader}
                  >
                    {loader ? "Loading..." : "Reset Password"}
                  </button>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
