import React, { useState } from "react";
import moment from "moment";
import Tooltip from "react-tooltip-lite";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  calculateDuration,
  convertTimeFormat,
  calculateTotalDuration,
} from "../GlobalFunction";

const FirstStep = ({
  bookingDetails,
  wayOfFlight,
  travellerDetails,
  currentYear,
  handleChangeTravellerDetails,
  errorMessage,
}) => {
  const date = new Date();
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

  const adultsCount = parseInt(sessionStorage.getItem("adults"), 10) || 0;
  const childrenCount = parseInt(sessionStorage.getItem("children"), 10) || 0;
  const infantsCount = parseInt(sessionStorage.getItem("infants"), 10) || 0;

  const passengerLabels = [
    ...Array(adultsCount).fill("Adult"),
    ...Array(childrenCount).fill("Child"),
    ...Array(infantsCount).fill("Infant"),
  ];

  return (
    <div className="d-flex flex-column gap-1rem">
      <div
        className=" p-2"
        style={{ borderRadius: "3px", border: "1px solid #0C8CE9", borderLeft: "4px solid #0C8CE9" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#0C8CE9"
          className="bi bi-check-circle mr-2 mb-1 ml-2"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
          <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
        </svg>
        Well done! This flight offers a 24-hour cancellation policy for an
        additional fee.
      </div>
      <div className="card p-3 shadow">
        <div>
          <div className="d-flex align-items-start justify-content-between gap-1">
            <div className="d-flex align-items-center gap-1">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 11C9 11.7956 9.31607 12.5587 9.87868 13.1213C10.4413 13.6839 11.2044 14 12 14C12.7956 14 13.5587 13.6839 14.1213 13.1213C14.6839 12.5587 15 11.7956 15 11C15 10.2044 14.6839 9.44129 14.1213 8.87868C13.5587 8.31607 12.7956 8 12 8C11.2044 8 10.4413 8.31607 9.87868 8.87868C9.31607 9.44129 9 10.2044 9 11Z"
                  stroke="#0C8CE9"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.657 16.6567L13.414 20.8997C13.039 21.2743 12.5306 21.4848 12.0005 21.4848C11.4704 21.4848 10.962 21.2743 10.587 20.8997L6.343 16.6567C5.22422 15.5379 4.46234 14.1124 4.15369 12.5606C3.84504 11.0087 4.00349 9.40022 4.60901 7.93844C5.21452 6.47665 6.2399 5.22725 7.55548 4.34821C8.87107 3.46918 10.4178 3 12 3C13.5822 3 15.1289 3.46918 16.4445 4.34821C17.7601 5.22725 18.7855 6.47665 19.391 7.93844C19.9965 9.40022 20.155 11.0087 19.8463 12.5606C19.5377 14.1124 18.7758 15.5379 17.657 16.6567Z"
                  stroke="#0C8CE9"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className="fs-20p text-uppercase fw-700">Departure</span>
            </div>

            <span className="d-flex align-items-center text-nowrap">
              Airline Number :
              <span className="text-primary ml-1" style={{ fontWeight: 600 }}>
                {
                  bookingDetails?.flights?.[0]?.flightDetails?.[0]
                    ?.flightInformation?.[0]?.flightOrtrainNumber?.[0]
                }
              </span>
            </span>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center gap-2">
              <img
                className="ticket-airline-icon"
                src={
                  bookingDetails?.flights?.[0]?.flightDetails?.[0]
                    ?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                    ?.image
                }
                alt=""
              />
              <span className="fs-18p">
                {
                  bookingDetails?.flights?.[0]?.flightDetails?.[0]
                    ?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                    ?.airline_name
                }
              </span>
            </div>
            <div className="d-flex gap-1rem">
              <div className="d-flex flex-column align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="28"
                  width="28"
                  viewBox="0 0 24 24"
                  fill="#0c8ce9"
                >
                  <path d="M21.949 10.1118C22.1634 10.912 21.6886 11.7345 20.8884 11.9489L5.2218 16.1467C4.77856 16.2655 4.31138 16.0674 4.08866 15.6662L1.46582 10.9415L2.91471 10.5533L5.3825 12.9979L10.4778 11.6326L5.96728 4.55896L7.89913 4.04132L14.8505 10.4609L20.1119 9.05113C20.9121 8.83671 21.7346 9.31159 21.949 10.1118ZM4.00013 19H20.0001V21H4.00013V19Z"></path>
                </svg>
                <span
                  style={{ height: "58px", borderLeft: "1px dashed #E1E1E1" }}
                ></span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="22"
                  width="22"
                  viewBox="0 0 24 24"
                  fill="#0c8ce9"
                >
                  <path d="M11 5.07089C7.93431 5.5094 5.5094 7.93431 5.07089 11H7V13H5.07089C5.5094 16.0657 7.93431 18.4906 11 18.9291V17H13V18.9291C16.0657 18.4906 18.4906 16.0657 18.9291 13H17V11H18.9291C18.4906 7.93431 16.0657 5.5094 13 5.07089V7H11V5.07089ZM3.05493 11C3.51608 6.82838 6.82838 3.51608 11 3.05493V1H13V3.05493C17.1716 3.51608 20.4839 6.82838 20.9451 11H23V13H20.9451C20.4839 17.1716 17.1716 20.4839 13 20.9451V23H11V20.9451C6.82838 20.4839 3.51608 17.1716 3.05493 13H1V11H3.05493ZM15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"></path>
                </svg>
                <span
                  style={{ height: "46px", borderLeft: "1px dashed #E1E1E1" }}
                ></span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="28"
                  width="28"
                  viewBox="0 0 24 24"
                  fill="#0c8ce9"
                >
                  <path d="M20.949 14.8882C20.7346 15.6884 19.9121 16.1633 19.1119 15.9489L3.44528 11.751C3.00205 11.6323 2.69653 11.2271 2.70424 10.7683L2.79514 5.36515L4.24403 5.75338L5.15891 9.10435L10.2542 10.4696L9.88479 2.08844L11.8166 2.60608L14.6269 11.6413L19.8883 13.0511C20.6885 13.2655 21.1634 14.088 20.949 14.8882ZM4.00008 19H20.0001V21H4.00008V19Z"></path>
                </svg>
              </div>
              <div className="d-flex flex-column gap-2rem">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center gap-2">
                    <p className="fw-600 mb-0 fs-16p">
                      {sessionStorage.getItem("city_from")}&nbsp;(
                      {
                        bookingDetails?.flights?.[0]?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.location?.[0]?.locationId
                      }
                      )
                    </p>
                    <p className="mb-0 fs-16p ml-1">
                      {moment(
                        bookingDetails?.flights?.[0]?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.productDateTime?.[0]
                          ?.dateOfDeparture?.[0],
                        "DDMMYY"
                      ).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  <p className="mb-0 fs-13p">
                    {convertTimeFormat(
                      bookingDetails?.flights?.[0]?.flightDetails?.[0]
                        ?.flightInformation?.[0]?.productDateTime?.[0]
                        ?.timeOfDeparture?.[0]
                    )}
                  </p>
                </div>
                <div className="d-flex flex-column align-items-start">
                  <p className="mb-0 text-secondary">
                    {bookingDetails?.flights?.[0]?.flightDetails?.length ===
                    1 ? (
                      "Direct"
                    ) : (
                      <span className="pointer">
                        <Tooltip
                          content={
                            bookingDetails?.flights?.[0]?.flightDetails &&
                            bookingDetails?.flights?.[0]?.flightDetails
                              ?.length > 0 &&
                            bookingDetails?.flights?.[0]?.flightDetails
                              .slice(
                                0,
                                bookingDetails?.flights?.[0]?.flightDetails
                                  .length - 1
                              )
                              ?.map(
                                (item, index) =>
                                  calculateDuration(
                                    item,
                                    item?.flightInformation?.[0]
                                      ?.productDateTime?.[0]
                                      ?.timeOfDeparture?.[0],
                                    item?.flightInformation?.[0]
                                      ?.productDateTime?.[0]
                                      ?.dateOfDeparture?.[0],
                                    item?.flightInformation?.[0]
                                      ?.productDateTime?.[0]
                                      ?.timeOfArrival?.[0],
                                    item?.flightInformation?.[0]
                                      ?.productDateTime?.[0]
                                      ?.dateOfArrival?.[0],
                                    index
                                  ) +
                                  (index < item?.flightDetails?.length - 2
                                    ? ", "
                                    : "")
                              )
                          }
                        >
                          {bookingDetails?.flights?.[0]?.flightDetails?.length -
                            1}
                          &nbsp;Stop
                        </Tooltip>
                      </span>
                    )}
                  </p>
                  <p className="mb-0 text-secondary">
                    {calculateTotalDuration(
                      bookingDetails?.flights?.[0]?.flightDetails
                    )}
                  </p>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center gap-2">
                    <p className="fw-600 mb-0 fs-16p">
                      {sessionStorage.getItem("city_to")}&nbsp; (
                      {
                        bookingDetails?.flights?.[0]?.flightDetails[
                          bookingDetails?.flights?.[0]?.flightDetails?.length -
                            1
                        ]?.flightInformation?.[0]?.location?.[1]?.locationId
                      }
                      )
                    </p>
                    <p className="mb-0 fs-16p ml-1">
                      {moment(
                        bookingDetails?.flights?.[0]?.flightDetails[
                          bookingDetails?.flights?.[0]?.flightDetails?.length -
                            1
                        ]?.flightInformation?.[0]?.productDateTime?.[0]
                          ?.dateOfArrival?.[0],
                        "DDMMYY"
                      ).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  <p className="mb-0">
                    {convertTimeFormat(
                      bookingDetails?.flights?.[0]?.flightDetails[
                        bookingDetails?.flights?.[0]?.flightDetails?.length - 1
                      ]?.flightInformation?.[0]?.productDateTime?.[0]
                        ?.timeOfArrival?.[0]
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {wayOfFlight === "round_trip" && (
          <div
            className="mt-3 pt-3"
            style={{ borderTop: "1px dashed #E1E1E1" }}
          >
            <div className="d-flex align-items-start justify-content-between gap-1">
              <div className="d-flex align-items-center gap-1">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 11C9 11.7956 9.31607 12.5587 9.87868 13.1213C10.4413 13.6839 11.2044 14 12 14C12.7956 14 13.5587 13.6839 14.1213 13.1213C14.6839 12.5587 15 11.7956 15 11C15 10.2044 14.6839 9.44129 14.1213 8.87868C13.5587 8.31607 12.7956 8 12 8C11.2044 8 10.4413 8.31607 9.87868 8.87868C9.31607 9.44129 9 10.2044 9 11Z"
                    stroke="#0C8CE9"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.657 16.6567L13.414 20.8997C13.039 21.2743 12.5306 21.4848 12.0005 21.4848C11.4704 21.4848 10.962 21.2743 10.587 20.8997L6.343 16.6567C5.22422 15.5379 4.46234 14.1124 4.15369 12.5606C3.84504 11.0087 4.00349 9.40022 4.60901 7.93844C5.21452 6.47665 6.2399 5.22725 7.55548 4.34821C8.87107 3.46918 10.4178 3 12 3C13.5822 3 15.1289 3.46918 16.4445 4.34821C17.7601 5.22725 18.7855 6.47665 19.391 7.93844C19.9965 9.40022 20.155 11.0087 19.8463 12.5606C19.5377 14.1124 18.7758 15.5379 17.657 16.6567Z"
                    stroke="#0C8CE9"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span className="fs-20p fw-700 text-uppercase">Return</span>
              </div>

              <span className="d-flex align-items-center text-nowrap">
                Airline Number :
                <span className="text-primary ml-1" style={{ fontWeight: 600 }}>
                  {
                    bookingDetails?.flights?.[1]?.flightDetails?.[0]
                      ?.flightInformation?.[0]?.flightOrtrainNumber?.[0]
                  }
                </span>
              </span>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center gap-2">
                <img
                  className="ticket-airline-icon"
                  src={
                    bookingDetails?.flights?.[1]?.flightDetails?.[0]
                      ?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                      ?.image
                  }
                  alt=""
                />
                <span className="fs-18p">
                  {
                    bookingDetails?.flights?.[1]?.flightDetails?.[0]
                      ?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                      ?.airline_name
                  }
                </span>
              </div>
              <div className="d-flex gap-1rem">
                <div className="d-flex flex-column align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="28"
                    width="28"
                    viewBox="0 0 24 24"
                    fill="#0c8ce9"
                  >
                    <path d="M21.949 10.1118C22.1634 10.912 21.6886 11.7345 20.8884 11.9489L5.2218 16.1467C4.77856 16.2655 4.31138 16.0674 4.08866 15.6662L1.46582 10.9415L2.91471 10.5533L5.3825 12.9979L10.4778 11.6326L5.96728 4.55896L7.89913 4.04132L14.8505 10.4609L20.1119 9.05113C20.9121 8.83671 21.7346 9.31159 21.949 10.1118ZM4.00013 19H20.0001V21H4.00013V19Z"></path>
                  </svg>
                  <span
                    style={{ height: "58px", borderLeft: "1px dashed #E1E1E1" }}
                  ></span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="22"
                    width="22"
                    viewBox="0 0 24 24"
                    fill="#0c8ce9"
                  >
                    <path d="M11 5.07089C7.93431 5.5094 5.5094 7.93431 5.07089 11H7V13H5.07089C5.5094 16.0657 7.93431 18.4906 11 18.9291V17H13V18.9291C16.0657 18.4906 18.4906 16.0657 18.9291 13H17V11H18.9291C18.4906 7.93431 16.0657 5.5094 13 5.07089V7H11V5.07089ZM3.05493 11C3.51608 6.82838 6.82838 3.51608 11 3.05493V1H13V3.05493C17.1716 3.51608 20.4839 6.82838 20.9451 11H23V13H20.9451C20.4839 17.1716 17.1716 20.4839 13 20.9451V23H11V20.9451C6.82838 20.4839 3.51608 17.1716 3.05493 13H1V11H3.05493ZM15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"></path>
                  </svg>
                  <span
                    style={{ height: "46px", borderLeft: "1px dashed #E1E1E1" }}
                  ></span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="28"
                    width="28"
                    viewBox="0 0 24 24"
                    fill="#0c8ce9"
                  >
                    <path d="M20.949 14.8882C20.7346 15.6884 19.9121 16.1633 19.1119 15.9489L3.44528 11.751C3.00205 11.6323 2.69653 11.2271 2.70424 10.7683L2.79514 5.36515L4.24403 5.75338L5.15891 9.10435L10.2542 10.4696L9.88479 2.08844L11.8166 2.60608L14.6269 11.6413L19.8883 13.0511C20.6885 13.2655 21.1634 14.088 20.949 14.8882ZM4.00008 19H20.0001V21H4.00008V19Z"></path>
                  </svg>
                </div>
                <div className="d-flex d-flex flex-column gap-2rem">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center gap-2">
                      <p className="fw-600 mb-0 fs-16p">
                        {sessionStorage.getItem("city_to")}&nbsp;(
                        {
                          bookingDetails?.flights?.[1]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.location?.[0]?.locationId
                        }
                        )
                      </p>
                      <p className="mb-0 fs-16p ml-1">
                        {moment(
                          bookingDetails?.flights?.[1]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.productDateTime?.[0]
                            ?.dateOfDeparture?.[0],
                          "DDMMYY"
                        ).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <p className="mb-0 fs-13p">
                      {convertTimeFormat(
                        bookingDetails?.flights?.[1]?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.productDateTime?.[0]
                          ?.timeOfDeparture?.[0]
                      )}
                    </p>
                  </div>
                  <div className="d-flex flex-column align-items-start">
                    <p className="mb-0 text-secondary">
                      {bookingDetails?.flights?.[1]?.flightDetails?.length ===
                      1 ? (
                        "Direct"
                      ) : (
                        <span className="pointer">
                          <Tooltip
                            content={
                              bookingDetails?.flights?.[1]?.flightDetails &&
                              bookingDetails?.flights?.[1]?.flightDetails
                                ?.length > 0 &&
                              bookingDetails?.flights?.[1]?.flightDetails
                                .slice(
                                  0,
                                  bookingDetails?.flights?.[1]?.flightDetails
                                    .length - 1
                                )
                                ?.map(
                                  (item, index) =>
                                    calculateDuration(
                                      item,
                                      item?.flightInformation?.[0]
                                        ?.productDateTime?.[0]
                                        ?.timeOfDeparture?.[0],
                                      item?.flightInformation?.[0]
                                        ?.productDateTime?.[0]
                                        ?.dateOfDeparture?.[0],
                                      item?.flightInformation?.[0]
                                        ?.productDateTime?.[0]
                                        ?.timeOfArrival?.[0],
                                      item?.flightInformation?.[0]
                                        ?.productDateTime?.[0]
                                        ?.dateOfArrival?.[0],
                                      index
                                    ) +
                                    (index < item?.flightDetails?.length - 2
                                      ? ", "
                                      : "")
                                )
                            }
                          >
                            {bookingDetails?.flights?.[1]?.flightDetails
                              ?.length - 1}
                            &nbsp;Stop
                          </Tooltip>
                        </span>
                      )}
                    </p>
                    <p className="mb-0 text-secondary">
                      {calculateTotalDuration(
                        bookingDetails?.flights?.[1]?.flightDetails
                      )}
                    </p>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0 fw-600 fs-16p">
                        {sessionStorage.getItem("city_from")}&nbsp;(
                        {
                          bookingDetails?.flights?.[1]?.flightDetails[
                            bookingDetails?.flights?.[1]?.flightDetails
                              ?.length - 1
                          ]?.flightInformation?.[0]?.location?.[1]?.locationId
                        }
                        )
                      </p>
                      <p className="mb-0 fs-16p ml-1">
                        {moment(
                          bookingDetails?.flights?.[1]?.flightDetails[
                            bookingDetails?.flights?.[1]?.flightDetails
                              ?.length - 1
                          ]?.flightInformation?.[0]?.productDateTime?.[0]
                            ?.dateOfArrival?.[0],
                          "DDMMYY"
                        ).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <p className="mb-0 fs-13p">
                      {convertTimeFormat(
                        bookingDetails?.flights?.[1]?.flightDetails[
                          bookingDetails?.flights?.[1]?.flightDetails?.length -
                            1
                        ]?.flightInformation?.[0]?.productDateTime?.[0]
                          ?.timeOfArrival?.[0]
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="card p-3 shadow mb-3">
        <div className="d-flex align-items-center gap-1 mb-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#0C8CE9"
            class="bi bi-people"
            viewBox="0 0 16 16"
          >
            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
          </svg>
          <span className="fs-18p fw-700 text-uppercase">Traveler Details</span>
        </div>

        {travellerDetails?.map((data, key) => {
          return (
            <>
              <div className="d-flex align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-person"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                </svg>
                <span className="fs-18p">
                  Passenger {key + 1}
                  &nbsp;
                  <span className="text-primary">{passengerLabels[key]}</span>
                </span>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="name">
                      First Name{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>
                    </label>
                    <input
                      type="text"
                      className="form-control fs-18p"
                      required
                      name="firstName"
                      style={{
                        borderRadius: "10px",
                      }}
                      value={data?.firstName}
                      onChange={(e) => {
                        handleChangeTravellerDetails(e, key);
                      }}
                      placeholder="Enter first name"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="name">Middle Name </label>
                    <input
                      type="text"
                      className="form-control fs-18p"
                      required
                      name="middleName"
                      style={{
                        borderRadius: "10px",
                      }}
                      value={data?.middleName}
                      onChange={(e) => {
                        handleChangeTravellerDetails(e, key);
                      }}
                      placeholder="Enter middle name"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="name">
                      Last Name{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>
                    </label>
                    <input
                      type="text"
                      className="form-control fs-18p"
                      required
                      name="lastName"
                      style={{
                        borderRadius: "10px",
                      }}
                      value={data?.lastName}
                      onChange={(e) => {
                        handleChangeTravellerDetails(e, key);
                      }}
                      placeholder="Enter last name"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group d-flex flex-column">
                    <label htmlFor="name">
                      Date of Birth{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        // value={
                        //   isValid(parseISO(data?.date))
                        //     ? parseISO(data?.date)
                        //     : null
                        // }
                        value={data?.date ? new Date(data.date) : null}
                        onChange={(newValue) =>
                          handleChangeTravellerDetails(
                            newValue,
                            key,
                            "datepicker"
                          )
                        }
                        views={["year", "month", "day"]}
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="banner-form-input w-100"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="name">
                      Gender{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>
                    </label>
                    <div>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          name={`gender-${key}`}
                          id={`male-${key}`}
                          value="Male"
                          checked={data.gender === "Male"}
                          onChange={(e) =>
                            handleChangeTravellerDetails(e, key, "radio")
                          }
                        />
                        <label className="form-check-label">Male</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          name={`gender-${key}`}
                          id={`female-${key}`}
                          value="Female"
                          checked={data.gender === "Female"}
                          onChange={(e) =>
                            handleChangeTravellerDetails(e, key, "radio")
                          }
                        />
                        <label className="form-check-label">Female</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        {errorMessage && (
          <span className="d-flex justify-content-end text-danger">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-exclamation-circle mt-1 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
            </svg>
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  );
};

export default FirstStep;
