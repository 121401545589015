import React, { useState } from "react";
import moment from "moment";
import Tooltip from "react-tooltip-lite";
import { Link } from "react-router-dom";
import allCountries from "../Countries";
import { Dropdown } from "react-bootstrap";
import {
  calculateDuration,
  convertTimeFormat,
  calculateTotalDuration,
} from "../GlobalFunction";

const ThirdStep = ({
  bookingDetails,
  wayOfFlight,
  isAllEmpty,
  cardDetails,
  setcardDetails,
  cardNumberDigit,
  setcardNumberDigit,
  billingDetails,
  setbillingDetails,
  travellerData,
  isChecked,
  setIsChecked,
  isValidCardNumber
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm1, setSearchTerm1] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    let formattedValue = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{0,2})/, "$1/$2")
      .slice(0, 5);

    if (formattedValue.length === 3 && value.length < formattedValue.length) {
      formattedValue = formattedValue.slice(0, 2);
    }
    const currentYear = new Date().getFullYear() % 100;
    const month = parseInt(formattedValue.slice(0, 2), 10);
    const year = parseInt(formattedValue.slice(3, 5), 10);
    if (month < 1 || month > 12) {
      formattedValue = formattedValue.slice(0, 2);
    }
    if (formattedValue.length === 5 && year < currentYear) {
      formattedValue = formattedValue.slice(0, 3);
    }
    setcardDetails({
      ...cardDetails,
      expDate: formattedValue,
    });
  };

  const handleChangeCvv = (event) => {
    const { value } = event.target;
    const formattedValue = value.replace(/\D/g, "").slice(0, 4);
    setcardDetails({
      ...cardDetails,
      cvv: formattedValue,
    });
  };

  const handleChangeNumber = (event) => {
    const inputValue = event.target.value.replace(/[^\d]/g, "");
    const formattedValue = inputValue.replace(/(\d{4})(?=\d)/g, "$1 ").trim();

    setcardDetails({
      ...cardDetails,
      number: formattedValue,
    });
  };

  const handleBillingDetails = (e) => {
    const { name, value } = e.target;
    setbillingDetails({
      ...billingDetails,
      [name]: value,
    });
  };

  const handleItemClick = (countryCode) => {
    setbillingDetails({
      ...billingDetails,
      countrycode: countryCode,
    });
  };

  const handleClickCountry = (country) => {
    setbillingDetails({
      ...billingDetails,
      country: country,
    });
  };

  const filteredCountries = allCountries?.filter((country) =>
    country?.country?.toLowerCase()?.includes(searchTerm.toLowerCase())
  );

  const filteredCountryCode = allCountries.filter(
    (con) =>
      con?.code?.includes(searchTerm1) ||
      con?.country?.toLowerCase()?.includes(searchTerm1?.toLowerCase())
  );

  function isValidCardHolderName(holderName) {
    if (!holderName) return false;
    const nameRegex = /^[a-zA-Z\s]+$/;
    return (
      nameRegex.test(holderName) && holderName.trim().split(" ").length >= 2
    );
  }

  // function isValidCardNumber(cardNumber) {
  //   if (!cardNumber) return false;
  //   const cleanedNumber = cardNumber.replace(/\s/g, "");
  //   if (cardDetails?.name === "American express") {
  //     return cleanedNumber.length === 15 && /^\d+$/.test(cleanedNumber);
  //   } else {
  //     return cleanedNumber.length === 16 && /^\d+$/.test(cleanedNumber);
  //   }
  // }

  function isValidExpDate(expDate) {
    if (!expDate) return false;
    const [month, year] = expDate.split("/");
    const currentYear = new Date().getFullYear() % 100;
    return (
      month >= "01" &&
      month <= "12" &&
      year >= currentYear.toString() &&
      year <= (currentYear + 30).toString()
    );
  }

  function isValidCVV(cvv, cardName) {
    if (!cvv) return false;
    if (cardName === "American express") {
      return cvv.length === 4 && /^\d+$/.test(cvv);
    } else {
      return cvv.length === 3 && /^\d+$/.test(cvv);
    }
  }

  const isCardNoLength = cardDetails?.name === "American express" ? cardDetails?.number?.length >= 18 : cardDetails?.number?.length >=19

  return (
    <>
      <div className="row">
        <div className="col-lg-7">
          <div className="card p-3 shadow mb-3">
            <div>
              <div className="d-flex align-items-center gap-1">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 11C9 11.7956 9.31607 12.5587 9.87868 13.1213C10.4413 13.6839 11.2044 14 12 14C12.7956 14 13.5587 13.6839 14.1213 13.1213C14.6839 12.5587 15 11.7956 15 11C15 10.2044 14.6839 9.44129 14.1213 8.87868C13.5587 8.31607 12.7956 8 12 8C11.2044 8 10.4413 8.31607 9.87868 8.87868C9.31607 9.44129 9 10.2044 9 11Z"
                    stroke="#0C8CE9"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.657 16.6567L13.414 20.8997C13.039 21.2743 12.5306 21.4848 12.0005 21.4848C11.4704 21.4848 10.962 21.2743 10.587 20.8997L6.343 16.6567C5.22422 15.5379 4.46234 14.1124 4.15369 12.5606C3.84504 11.0087 4.00349 9.40022 4.60901 7.93844C5.21452 6.47665 6.2399 5.22725 7.55548 4.34821C8.87107 3.46918 10.4178 3 12 3C13.5822 3 15.1289 3.46918 16.4445 4.34821C17.7601 5.22725 18.7855 6.47665 19.391 7.93844C19.9965 9.40022 20.155 11.0087 19.8463 12.5606C19.5377 14.1124 18.7758 15.5379 17.657 16.6567Z"
                    stroke="#0C8CE9"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span className="fs-20p">Departure</span>
              </div>

              <div className="d-flex align-items-center gap-2">
                <img
                  className="ticket-airline-icon"
                  src={
                    bookingDetails?.flights?.[0]?.flightDetails?.[0]
                      ?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                      ?.image
                  }
                  alt=""
                />
                <span
                  className="fs-15p"
                  style={{
                    fontWeight: 400,
                  }}
                >
                  {
                    bookingDetails?.flights?.[0]?.flightDetails?.[0]
                      ?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                      ?.airline_name
                  }
                </span>
              </div>

              <div className="d-flex gap-1rem">
                <div className="d-flex flex-column align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="28"
                    width="28"
                    viewBox="0 0 24 24"
                    fill="#0c8ce9"
                  >
                    <path d="M21.949 10.1118C22.1634 10.912 21.6886 11.7345 20.8884 11.9489L5.2218 16.1467C4.77856 16.2655 4.31138 16.0674 4.08866 15.6662L1.46582 10.9415L2.91471 10.5533L5.3825 12.9979L10.4778 11.6326L5.96728 4.55896L7.89913 4.04132L14.8505 10.4609L20.1119 9.05113C20.9121 8.83671 21.7346 9.31159 21.949 10.1118ZM4.00013 19H20.0001V21H4.00013V19Z"></path>
                  </svg>
                  <span
                    style={{
                      height: "52px",
                      borderLeft: "1px dashed #E1E1E1",
                    }}
                  ></span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="22"
                    width="22"
                    viewBox="0 0 24 24"
                    fill="#0c8ce9"
                  >
                    <path d="M11 5.07089C7.93431 5.5094 5.5094 7.93431 5.07089 11H7V13H5.07089C5.5094 16.0657 7.93431 18.4906 11 18.9291V17H13V18.9291C16.0657 18.4906 18.4906 16.0657 18.9291 13H17V11H18.9291C18.4906 7.93431 16.0657 5.5094 13 5.07089V7H11V5.07089ZM3.05493 11C3.51608 6.82838 6.82838 3.51608 11 3.05493V1H13V3.05493C17.1716 3.51608 20.4839 6.82838 20.9451 11H23V13H20.9451C20.4839 17.1716 17.1716 20.4839 13 20.9451V23H11V20.9451C6.82838 20.4839 3.51608 17.1716 3.05493 13H1V11H3.05493ZM15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"></path>
                  </svg>
                  <span
                    style={{
                      height: "46px",
                      borderLeft: "1px dashed #E1E1E1",
                    }}
                  ></span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="28"
                    width="28"
                    viewBox="0 0 24 24"
                    fill="#0c8ce9"
                  >
                    <path d="M20.949 14.8882C20.7346 15.6884 19.9121 16.1633 19.1119 15.9489L3.44528 11.751C3.00205 11.6323 2.69653 11.2271 2.70424 10.7683L2.79514 5.36515L4.24403 5.75338L5.15891 9.10435L10.2542 10.4696L9.88479 2.08844L11.8166 2.60608L14.6269 11.6413L19.8883 13.0511C20.6885 13.2655 21.1634 14.088 20.949 14.8882ZM4.00008 19H20.0001V21H4.00008V19Z"></path>
                  </svg>
                </div>
                <div className="d-flex flex-column gap-2rem">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0 fw-600">
                        {sessionStorage.getItem("city_from")}&nbsp;(
                        {
                          bookingDetails?.flights?.[0]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.location?.[0]?.locationId
                        }
                        )
                      </p>

                      <p className="mb-0 ml-1">
                        {moment(
                          bookingDetails?.flights?.[0]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.productDateTime?.[0]
                            ?.dateOfDeparture?.[0],
                          "DDMMYY"
                        ).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <p className="mb-0 fs-13p">
                      {convertTimeFormat(
                        bookingDetails?.flights?.[0]?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.productDateTime?.[0]
                          ?.timeOfDeparture?.[0]
                      )}
                    </p>
                  </div>
                  <div className="d-flex flex-column align-items-start">
                    <p className="mb-0 text-secondary">
                      {bookingDetails?.flights?.[0]?.flightDetails?.length ===
                      1 ? (
                        "Direct"
                      ) : (
                        <span className="pointer">
                          <Tooltip
                            content={
                              bookingDetails?.flights?.[0]?.flightDetails &&
                              bookingDetails?.flights?.[0]?.flightDetails
                                ?.length > 0 &&
                              bookingDetails?.flights?.[0]?.flightDetails
                                .slice(
                                  0,
                                  bookingDetails?.flights?.[0]?.flightDetails
                                    .length - 1
                                )
                                ?.map(
                                  (item, index) =>
                                    calculateDuration(
                                      item,
                                      item?.flightInformation?.[0]
                                        ?.productDateTime?.[0]
                                        ?.timeOfDeparture?.[0],
                                      item?.flightInformation?.[0]
                                        ?.productDateTime?.[0]
                                        ?.dateOfDeparture?.[0],
                                      item?.flightInformation?.[0]
                                        ?.productDateTime?.[0]
                                        ?.timeOfArrival?.[0],
                                      item?.flightInformation?.[0]
                                        ?.productDateTime?.[0]
                                        ?.dateOfArrival?.[0],
                                      index
                                    ) +
                                    (index < item?.flightDetails?.length - 2
                                      ? ", "
                                      : "")
                                )
                            }
                          >
                            {bookingDetails?.flights?.[0]?.flightDetails
                              ?.length - 1}
                            &nbsp;Stop
                          </Tooltip>
                        </span>
                      )}
                    </p>
                    <p className="mb-0 text-secondary">
                      {calculateTotalDuration(
                        bookingDetails?.flights?.[0]?.flightDetails
                      )}
                    </p>
                  </div>

                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center gap-2">
                      <p className="fw-600 mb-0">
                        {sessionStorage.getItem("city_to")}&nbsp;(
                        {
                          bookingDetails?.flights?.[0]?.flightDetails[
                            bookingDetails?.flights?.[0]?.flightDetails
                              ?.length - 1
                          ]?.flightInformation?.[0]?.location?.[1]?.locationId
                        }
                        )
                      </p>
                      <p className="mb-0 ml-1">
                        {moment(
                          bookingDetails?.flights?.[0]?.flightDetails[
                            bookingDetails?.flights?.[0]?.flightDetails
                              ?.length - 1
                          ]?.flightInformation?.[0]?.productDateTime?.[0]
                            ?.dateOfArrival?.[0],
                          "DDMMYY"
                        ).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <p className="mb-0">
                      {convertTimeFormat(
                        bookingDetails?.flights?.[0]?.flightDetails[
                          bookingDetails?.flights?.[0]?.flightDetails?.length -
                            1
                        ]?.flightInformation?.[0]?.productDateTime?.[0]
                          ?.timeOfArrival?.[0]
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {wayOfFlight === "round_trip" && (
              <div
                className="mt-3 pt-3"
                style={{ borderTop: "1px dashed #E1E1E1" }}
              >
                <div className="d-flex align-items-center gap-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 11C9 11.7956 9.31607 12.5587 9.87868 13.1213C10.4413 13.6839 11.2044 14 12 14C12.7956 14 13.5587 13.6839 14.1213 13.1213C14.6839 12.5587 15 11.7956 15 11C15 10.2044 14.6839 9.44129 14.1213 8.87868C13.5587 8.31607 12.7956 8 12 8C11.2044 8 10.4413 8.31607 9.87868 8.87868C9.31607 9.44129 9 10.2044 9 11Z"
                      stroke="#0C8CE9"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.657 16.6567L13.414 20.8997C13.039 21.2743 12.5306 21.4848 12.0005 21.4848C11.4704 21.4848 10.962 21.2743 10.587 20.8997L6.343 16.6567C5.22422 15.5379 4.46234 14.1124 4.15369 12.5606C3.84504 11.0087 4.00349 9.40022 4.60901 7.93844C5.21452 6.47665 6.2399 5.22725 7.55548 4.34821C8.87107 3.46918 10.4178 3 12 3C13.5822 3 15.1289 3.46918 16.4445 4.34821C17.7601 5.22725 18.7855 6.47665 19.391 7.93844C19.9965 9.40022 20.155 11.0087 19.8463 12.5606C19.5377 14.1124 18.7758 15.5379 17.657 16.6567Z"
                      stroke="#0C8CE9"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="fs-20p">Return</span>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      className="ticket-airline-icon"
                      src={
                        bookingDetails?.flights?.[1]?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.companyId?.[0]
                          ?.customCarriers?.image
                      }
                      alt=""
                    />
                    <span
                      className="fs-15p"
                      style={{
                        fontWeight: 400,
                      }}
                    >
                      {
                        bookingDetails?.flights?.[1]?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.companyId?.[0]
                          ?.customCarriers?.airline_name
                      }
                    </span>
                  </div>
                  <div className="d-flex gap-1rem">
                    <div className="d-flex flex-column align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="28"
                        width="28"
                        viewBox="0 0 24 24"
                        fill="#0c8ce9"
                      >
                        <path d="M21.949 10.1118C22.1634 10.912 21.6886 11.7345 20.8884 11.9489L5.2218 16.1467C4.77856 16.2655 4.31138 16.0674 4.08866 15.6662L1.46582 10.9415L2.91471 10.5533L5.3825 12.9979L10.4778 11.6326L5.96728 4.55896L7.89913 4.04132L14.8505 10.4609L20.1119 9.05113C20.9121 8.83671 21.7346 9.31159 21.949 10.1118ZM4.00013 19H20.0001V21H4.00013V19Z"></path>
                      </svg>
                      <span
                        style={{
                          height: "52px",
                          borderLeft: "1px dashed #E1E1E1",
                        }}
                      ></span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="22"
                        width="22"
                        viewBox="0 0 24 24"
                        fill="#0c8ce9"
                      >
                        <path d="M11 5.07089C7.93431 5.5094 5.5094 7.93431 5.07089 11H7V13H5.07089C5.5094 16.0657 7.93431 18.4906 11 18.9291V17H13V18.9291C16.0657 18.4906 18.4906 16.0657 18.9291 13H17V11H18.9291C18.4906 7.93431 16.0657 5.5094 13 5.07089V7H11V5.07089ZM3.05493 11C3.51608 6.82838 6.82838 3.51608 11 3.05493V1H13V3.05493C17.1716 3.51608 20.4839 6.82838 20.9451 11H23V13H20.9451C20.4839 17.1716 17.1716 20.4839 13 20.9451V23H11V20.9451C6.82838 20.4839 3.51608 17.1716 3.05493 13H1V11H3.05493ZM15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"></path>
                      </svg>
                      <span
                        style={{
                          height: "46px",
                          borderLeft: "1px dashed #E1E1E1",
                        }}
                      ></span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="28"
                        width="28"
                        viewBox="0 0 24 24"
                        fill="#0c8ce9"
                      >
                        <path d="M20.949 14.8882C20.7346 15.6884 19.9121 16.1633 19.1119 15.9489L3.44528 11.751C3.00205 11.6323 2.69653 11.2271 2.70424 10.7683L2.79514 5.36515L4.24403 5.75338L5.15891 9.10435L10.2542 10.4696L9.88479 2.08844L11.8166 2.60608L14.6269 11.6413L19.8883 13.0511C20.6885 13.2655 21.1634 14.088 20.949 14.8882ZM4.00008 19H20.0001V21H4.00008V19Z"></path>
                      </svg>
                    </div>
                    <div className="d-flex flex-column gap-2rem">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center gap-2">
                          <p className="fw-600 mb-0">
                            {sessionStorage.getItem("city_to")}&nbsp;(
                            {
                              bookingDetails?.flights?.[1]?.flightDetails?.[0]
                                ?.flightInformation?.[0]?.location?.[0]
                                ?.locationId
                            }
                            )
                          </p>

                          <p className="mb-0">
                            {moment(
                              bookingDetails?.flights?.[1]?.flightDetails?.[0]
                                ?.flightInformation?.[0]?.productDateTime?.[0]
                                ?.dateOfDeparture?.[0],
                              "DDMMYY"
                            ).format("DD/MM/YYYY")}
                          </p>
                        </div>
                        <p className="mb-0 fs-13p">
                          {convertTimeFormat(
                            bookingDetails?.flights?.[1]?.flightDetails?.[0]
                              ?.flightInformation?.[0]?.productDateTime?.[0]
                              ?.timeOfDeparture?.[0]
                          )}
                        </p>
                      </div>
                      <div className="d-flex flex-column align-items-start">
                        <p className="mb-0 text-secondary">
                          {bookingDetails?.flights?.[1]?.flightDetails
                            ?.length === 1 ? (
                            "Direct"
                          ) : (
                            <span className="pointer">
                              <Tooltip
                                content={
                                  bookingDetails?.flights?.[1]?.flightDetails &&
                                  bookingDetails?.flights?.[1]?.flightDetails
                                    ?.length > 0 &&
                                  bookingDetails?.flights?.[1]?.flightDetails
                                    .slice(
                                      0,
                                      bookingDetails?.flights?.[1]
                                        ?.flightDetails.length - 1
                                    )
                                    ?.map(
                                      (item, index) =>
                                        calculateDuration(
                                          item,
                                          item?.flightInformation?.[0]
                                            ?.productDateTime?.[0]
                                            ?.timeOfDeparture?.[0],
                                          item?.flightInformation?.[0]
                                            ?.productDateTime?.[0]
                                            ?.dateOfDeparture?.[0],
                                          item?.flightInformation?.[0]
                                            ?.productDateTime?.[0]
                                            ?.timeOfArrival?.[0],
                                          item?.flightInformation?.[0]
                                            ?.productDateTime?.[0]
                                            ?.dateOfArrival?.[0],
                                          index
                                        ) +
                                        (index < item?.flightDetails?.length - 2
                                          ? ", "
                                          : "")
                                    )
                                }
                              >
                                {bookingDetails?.flights?.[1]?.flightDetails
                                  ?.length - 1}
                                &nbsp;Stop
                              </Tooltip>
                            </span>
                          )}
                        </p>
                        <p className="mb-0 text-secondary">
                          {calculateTotalDuration(
                            bookingDetails?.flights?.[1]?.flightDetails
                          )}
                        </p>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center gap-2">
                          <p className="fw-600 mb-0">
                            {sessionStorage.getItem("city_from")}&nbsp;(
                            {
                              bookingDetails?.flights?.[1]?.flightDetails[
                                bookingDetails?.flights?.[1]?.flightDetails
                                  ?.length - 1
                              ]?.flightInformation?.[0]?.location?.[1]
                                ?.locationId
                            }
                            )
                          </p>
                          <p className="mb-0">
                            {moment(
                              bookingDetails?.flights?.[1]?.flightDetails[
                                bookingDetails?.flights?.[1]?.flightDetails
                                  ?.length - 1
                              ]?.flightInformation?.[0]?.productDateTime?.[0]
                                ?.dateOfArrival?.[0],
                              "DDMMYY"
                            ).format("DD/MM/YYYY")}
                          </p>
                        </div>
                        <p className="mb-0">
                          {convertTimeFormat(
                            bookingDetails?.flights?.[1]?.flightDetails[
                              bookingDetails?.flights?.[1]?.flightDetails
                                ?.length - 1
                            ]?.flightInformation?.[0]?.productDateTime?.[0]
                              ?.timeOfArrival?.[0]
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-5">
          <div className="card p-3 shadow mb-3 ">
            <div className="d-flex align-items-center gap-1 mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#0C8CE9"
                class="bi bi-people"
                viewBox="0 0 16 16"
              >
                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
              </svg>
              <span className="fs-20p">Traveler Details</span>
            </div>
            {isAllEmpty ? (
              <div className="mt-2">No Travellers Added</div>
            ) : (
              <>
                <div
                  className={`d-flex flex-column gap-2  ${
                    wayOfFlight === "round_trip"
                      ? "responsive-mh"
                      : "responsive1-mh"
                  }`}
                  style={{
                    overflowY: "scroll",
                  }}
                >
                  {travellerData?.map((data) => (
                    <>
                      <div className="d-flex flex-column p-3 rounded-10p bg-light shadow-sm">
                        <div className="d-flex align-items-center gap-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-person"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                          </svg>
                          <span
                            style={{
                              fontWeight: 400,
                              marginLeft: "8px",
                            }}
                          >
                            {data?.firstName}&nbsp;{data?.middleName}&nbsp;
                            {data?.lastName}
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-1 mt-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-calendar3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                          </svg>
                          <span
                            style={{
                              fontWeight: 400,
                              marginLeft: "8px",
                            }}
                          >
                            {moment(data?.date).format("DD/MM/YYYY")}
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-1 mt-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-gender-neuter"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8M3 5a5 5 0 1 1 5.5 4.975V15.5a.5.5 0 0 1-1 0V9.975A5 5 0 0 1 3 5"
                            />
                          </svg>
                          <span
                            style={{
                              fontWeight: 400,
                              marginLeft: "8px",
                            }}
                          >
                            {data?.gender}
                          </span>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="card p-3 shadow mb-3">
        <span className="fs-20p">Card Details</span>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label htmlFor="name">
                Card Name{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  class="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>{" "}
                :
              </label>
              <select
                required
                class="form-control"
                value={cardDetails?.name}
                name="exampleSelect"
                onChange={(event) => {
                  setcardDetails({
                    ...cardDetails,
                    name: event.target.value,
                    number: "",
                    expDate: "",
                    cvv: "",
                  });
                  setcardNumberDigit(
                    event.target.value === "American express" ? 15 : 16
                  );
                }}
              >
                <option value="">Select Card</option>
                <option value="American express">American express</option>
                <option value="Master card">Master card</option>
                <option value="Visa">Visa</option>
                <option value="Discover">Discover</option>
              </select>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label htmlFor="name">
                Card Holder Name{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  class="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>{" "}
                :
              </label>
              <div className="input-with-icon">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  required
                  value={cardDetails?.holderName}
                  onChange={(event) =>
                    setcardDetails({
                      ...cardDetails,
                      holderName: event.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label htmlFor="name">
                Card Number{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  class="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>{" "}
                :
              </label>
              <div className="input-with-icon">
                <input
                  className="form-control"
                  disabled={!cardDetails.name}
                  id="ccn"
                  type="tel"
                  inputmode="numeric"
                  pattern="[0-9\s]{13,19}"
                  autocomplete="cc-number"
                  maxlength={
                    cardDetails?.name === "American express" ? "18" : "19"
                  }
                  placeholder={
                    cardDetails?.name === "American express"
                      ? "xxxx xxxx xxxx xxx"
                      : "xxxx xxxx xxxx xxxx"
                  }
                  required
                  value={cardDetails?.number}
                  onChange={handleChangeNumber}
                />
                {/* {isValidCardNumber(cardDetails?.number) && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-check text-success"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                  </svg>
                )} */}
                {cardDetails?.number && isCardNoLength && (
                  isValidCardNumber(cardDetails?.number) ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-check text-success"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-x text-danger"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label>
                Card Expiry Date{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  class="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>
              </label>
              <div className="input-with-icon">
                <input
                  className="form-control"
                  type="text"
                  id="expiry-date"
                  name="expiry-date"
                  disabled={!cardDetails.name}
                  value={cardDetails?.expDate}
                  onChange={handleChange}
                  maxLength="5"
                  placeholder="MM/YY"
                />
                {isValidExpDate(cardDetails?.expDate) && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-check text-success"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                  </svg>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label>
                CVV{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  class="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>
              </label>
              <div className="input-with-icon">
                <input
                  className="form-control"
                  type="text"
                  id="cvv"
                  name="cvv"
                  maxlength={
                    cardDetails.name === "American express" ? "4" : "3"
                  }
                  pattern="\d{3,4}"
                  disabled={!cardDetails.name}
                  placeholder={
                    cardDetails.name === "American express" ? "xxxx" : "xxx"
                  }
                  required
                  value={cardDetails.cvv}
                  onChange={handleChangeCvv}
                />
                {isValidCVV(cardDetails.cvv, cardDetails.name) && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-check text-success"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card p-3 shadow mb-3">
        <span className="fs-20p">Billing Details</span>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div class="form-group">
              <label htmlFor="name">
                Name{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  class="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>{" "}
                :
              </label>
              <input
                type="text"
                class="form-control"
                name="name"
                required
                value={billingDetails?.name}
                onChange={handleBillingDetails}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div class="form-group">
              <label htmlFor="email">
                Email{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  class="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>{" "}
                :
              </label>
              <input
                type="email"
                class="form-control"
                name="email"
                required
                value={billingDetails?.email}
                onChange={handleBillingDetails}
              />
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label htmlFor="address">
                Country{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  className="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>{" "}
                :
              </label>
              <Dropdown autoClose="inside">
                <Dropdown.Toggle
                  className="form-control bg-white text-dark dropdown-toggle-span"
                  id="country-dropdown"
                  style={{ border: "1px solid #ced4da" }}
                >
                  {billingDetails.country || "Choose Country"}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ zIndex: 9999, width: "250px" }}>
                  <div className="form-group px-2 mb-0">
                    <input
                      type="text"
                      placeholder="Search by country or code.."
                      className="form-control mb-2"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div style={{ height: "180px", overflowY: "scroll" }}>
                      {filteredCountries?.map((con) => (
                        <Dropdown.Item
                          className="form-label w-100 text-truncate mb-0 pb-1 px-2 bg-light mb-1 rounded pointer"
                          key={con?.country}
                          onClick={() => handleClickCountry(con?.country)}
                        >
                          {con?.flag}&nbsp;
                          {con?.country}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label>
                Phone Number{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  className="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>{" "}
                :
              </label>
              <div className="modified-input-group">
                <div className="modified-input-group-prepend">
                  <Dropdown autoClose="inside">
                    <Dropdown.Toggle
                      className="nav-link btn-light bg-white custom-dropdown-btn border w-100"
                      id="country-code-dropdown"
                    >
                      {billingDetails.countrycode || "Code"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ zIndex: 9999, width: "250px" }}>
                      <div className="form-group px-2 mb-0">
                        <input
                          type="text"
                          placeholder="Search by country or code.."
                          className="form-control mb-2"
                          value={searchTerm1}
                          onChange={(e) => setSearchTerm1(e.target.value)}
                        />
                        <div style={{ height: "180px", overflowY: "scroll" }}>
                          {filteredCountryCode.map((con) => (
                            <div
                              className="form-label w-100 text-truncate mb-0 pb-1 px-2 bg-light mb-1 rounded pointer"
                              // key={con?.code}
                              onClick={() => handleItemClick(con?.code)}
                            >
                              {con?.flag}&nbsp;{con?.code}&nbsp;
                              {con?.country}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <input
                  type="number"
                  className="modified-form-control"
                  name="phone"
                  required
                  value={billingDetails?.phone}
                  onChange={handleBillingDetails}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
            <div class="form-group">
              <label htmlFor="address">
                Address{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  class="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>{" "}
                :
              </label>
              <input
                type="text"
                class="form-control"
                name="address"
                required
                value={billingDetails?.address}
                onChange={handleBillingDetails}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div class="form-group">
              <label htmlFor="state">
                State{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  class="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>{" "}
                :
              </label>
              <input
                type="text"
                class="form-control"
                name="state"
                required
                value={billingDetails?.state}
                onChange={handleBillingDetails}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div class="form-group">
              <label htmlFor="city">
                City{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  class="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>{" "}
                :
              </label>
              <input
                type="text"
                class="form-control"
                name="city"
                required
                value={billingDetails?.city}
                onChange={handleBillingDetails}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div class="form-group">
              <label htmlFor="zip">
                Zip{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  fill="currentColor"
                  class="bi bi-asterisk mb-2 text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                </svg>{" "}
                :
              </label>
              <input
                type="text"
                class="form-control"
                name="zip"
                required
                value={billingDetails?.zip}
                onChange={handleBillingDetails}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <input
          className="form-check-input pointer"
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <span className="mb-3">
          By checking this box you agree that you have read all details of your
          trip along with the instructions below and accepted our{" "}
          <Link to="/terms_conditions">Terms and Conditions</Link>, Cancellation
          and <Link to="/privacy_policy">Privacy policies</Link>. You also
          acknowledge that this will serve as your electronic signature and
          one-time authorization for the charges on your credit/debit card
        </span>
      </div>
    </>
  );
};

export default ThirdStep;
