import React, { useEffect, useState } from "react";
import Complete from "./Complete";
import Cancel from "./Cancel";
import { Modal, Button } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import from_to from "../image/icon/from_to.jpg";
import Header from "./Header";
import { useHistory, Link } from "react-router-dom";

const Bookings = () => {
  const login_data = JSON.parse(sessionStorage.getItem("login"));
  const history = useHistory();
  const [bookingList, setBookingList] = useState([]);
  const [show, setShow] = useState(false);
  const [selectTab, setSelectTab] = useState("bookings");
  const [loader, setloader] = useState(false);

  const tabs = [
    {
      name: "Upcoming",
      value: "bookings",
    },
    {
      name: "Complete",
      value: "complete",
    },
    {
      name: "Cancel",
      value: "cancel",
    },
  ];

  const handleTabChange = (mode) => {
    setSelectTab(mode);
  };

  const handleGetAllBookings = async () => {
    setloader(true);
    try {
      const response = await fetch(
        "https://api.ogule.com/api/management/get-all-bookings",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${login_data.tokens.refresh.token}`,
          },
          body: JSON.stringify({
            page: 1,
            limit: 200,
            filters: {
              customer: login_data.user._id,
            },
          }),
        }
      );
      const res = await response.json();
      if (res.success) {
        setBookingList(res?.result?.data);
        setloader(false);
      } else {
        sessionStorage.removeItem("login");
        history.push("/");
      }
    } catch (error) {
      setloader(false);
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    handleGetAllBookings();
  }, [selectTab]);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768 || window.innerWidth >= 768) {
        window.location.reload();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="px-responsive">
      {window.innerWidth > 768 ? (
        <>
          <div className="profile_top_sec">
            <ul className="list-group list-group-horizontal">
              {tabs?.map((data) => {
                return (
                  <li
                    className={`list-group-item pointer ${
                      selectTab === data?.value ? "active" : ""
                    }`}
                    onClick={() => {
                      handleTabChange(data?.value);
                    }}
                  >
                    {data?.name}
                  </li>
                );
              })}
            </ul>
            {selectTab === "bookings" && (
              <div className="container-fluid">
                {!loader && bookingList?.length > 0 && (
                  <div className="row">
                    {bookingList
                      ?.filter(
                        (item) =>
                          item?.status === "Pending" ||
                          item?.status === "Processing"
                      )
                      ?.map((item) => {
                        return (
                          <div className="col-12 mt-3" key={item.id}>
                            {/* <span className="badge badge-pill badge-primary">
                          Value For Money
                        </span> */}
                            <div className="card shadow px-3 py-2">
                              {item?.isOnline && (
                                <>
                                  <div className="d-flex align-items-center justify-content-evenly gap-1rem">
                                    <div className="d-flex flex-column gap-2">
                                      <div className="d-flex gap-12p align-items-center mb-0 border-dashed px-4 py-1 rounded border-secondary">
                                        <img
                                          style={{
                                            width: "80px",
                                            height: "80px",
                                            objectFit: "contain",
                                            objectPosition: "center",
                                          }}
                                          src={
                                            item?.booking_details
                                              ?.itineraries?.[0]?.segments?.[0]
                                              ?.airlineImage
                                          }
                                          alt="logo"
                                        />
                                        <div className="d-flex flex-column align-items-end">
                                          <b>
                                            {
                                              item?.booking_details
                                                ?.itineraries?.[0]
                                                ?.segments?.[0]?.departure
                                                ?.iataCode?.[0]
                                            }
                                          </b>
                                          <p className="mb-0">
                                            {moment(
                                              item?.booking_details
                                                ?.itineraries?.[0]
                                                ?.segments?.[0]?.departure?.at
                                            ).format("DD/MM/YYYY - h:mm A")}
                                          </p>
                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                          <span>
                                            {
                                              item?.booking_details
                                                ?.itineraries?.[0]?.duration
                                            }
                                          </span>
                                          <img
                                            style={{
                                              minWidth: "80",
                                              maxWidth: "220px",
                                            }}
                                            src={from_to}
                                            alt=""
                                          />
                                          <p className="mb-0 text-secondary fs-12p">
                                            {item?.booking_details
                                              ?.itineraries?.[0]?.segments
                                              ?.length === 1 ? (
                                              "Direct"
                                            ) : (
                                              <span className="pointer">
                                                {item?.booking_details
                                                  ?.itineraries?.[0]?.segments
                                                  ?.length - 1}
                                                &nbsp;Stop
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                        <div className="d-flex flex-column align-items-start">
                                          <b>
                                            {
                                              item?.booking_details
                                                ?.itineraries?.[0]?.segments[
                                                item?.booking_details
                                                  ?.itineraries?.[0]?.segments
                                                  ?.length - 1
                                              ]?.arrival?.iataCode?.[0]
                                            }
                                          </b>
                                          <p className="mb-0">
                                            {moment(
                                              item?.booking_details
                                                ?.itineraries?.[0]?.segments[
                                                item?.booking_details
                                                  ?.itineraries?.[0]?.segments
                                                  ?.length - 1
                                              ]?.arrival?.at
                                            ).format("DD/MM/YYYY - h:mm A")}
                                          </p>
                                        </div>
                                      </div>

                                      {item?.wayOfFlight === "round_trip" && (
                                        <div className="d-flex gap-12p align-items-center mb-0 border-dashed px-4 py-1 rounded border-secondary">
                                          <img
                                            style={{
                                              width: "80px",
                                              height: "80px",
                                              objectFit: "contain",
                                              objectPosition: "center",
                                            }}
                                            src={
                                              item?.booking_details
                                                ?.itineraries?.[1]
                                                ?.segments?.[0]?.airlineImage
                                            }
                                            alt="logo"
                                          />
                                          <div className="d-flex flex-column align-items-end">
                                            <b>
                                              {
                                                item?.booking_details
                                                  ?.itineraries?.[1]
                                                  ?.segments?.[0]?.departure
                                                  ?.iataCode?.[0]
                                              }
                                            </b>
                                            <p className="mb-0">
                                              {moment(
                                                item?.booking_details
                                                  ?.itineraries?.[1]
                                                  ?.segments?.[0]?.departure?.at
                                              ).format("DD/MM/YYYY - h:mm A")}
                                            </p>
                                          </div>
                                          <div className="d-flex flex-column align-items-center">
                                            <span>
                                              {
                                                item?.booking_details
                                                  ?.itineraries?.[1]?.duration
                                              }
                                            </span>
                                            <img
                                              style={{
                                                minWidth: "80",
                                                maxWidth: "220px",
                                              }}
                                              src={from_to}
                                              alt=""
                                            />
                                            <p className="mb-0 text-secondary fs-12p">
                                              {item?.booking_details
                                                ?.itineraries?.[1]?.segments
                                                ?.length === 1 ? (
                                                "Direct"
                                              ) : (
                                                <span className="pointer">
                                                  {item?.booking_details
                                                    ?.itineraries?.[1]?.segments
                                                    ?.length - 1}
                                                  &nbsp;Stop
                                                </span>
                                              )}
                                            </p>
                                          </div>
                                          <div className="d-flex flex-column align-items-start">
                                            <b>
                                              {
                                                item?.booking_details
                                                  ?.itineraries?.[1]?.segments[
                                                  item?.booking_details
                                                    ?.itineraries?.[0]?.segments
                                                    ?.length - 1
                                                ]?.arrival?.iataCode?.[0]
                                              }
                                            </b>
                                            <p className="mb-0">
                                              {moment(
                                                item?.booking_details
                                                  ?.itineraries?.[1]?.segments[
                                                  item?.booking_details
                                                    ?.itineraries?.[1]?.segments
                                                    ?.length - 1
                                                ]?.arrival?.at
                                              ).format("DD/MM/YYYY - h:mm A")}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                              {!item?.isOnline && (
                                <span className="d-flex justify-content-center mt-1">
                                  Flight details to be added
                                </span>
                              )}
                              <div className="d-flex justify-content-evenly mt-3 px-3">
                                <span className="d-flex align-items-center mb-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#ffa500"
                                    class="bi bi-check-square-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                  </svg>
                                  &nbsp;<b>Class</b>&nbsp;:&nbsp;
                                  {item?.travelClass}
                                </span>

                                <span className="d-flex align-items-center mb-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#ffa500"
                                    class="bi bi-airplane-engines-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.35 4.35 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0" />
                                  </svg>
                                  &nbsp;<b>Trip type</b>&nbsp;:&nbsp;
                                  {item?.wayOfFlight === "one_way"
                                    ? "One Way"
                                    : "Round Trip"}
                                </span>

                                <span className="d-flex align-items-center mb-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#ffa500"
                                    class="bi bi-check-square-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                  </svg>
                                  &nbsp;<b>Travellers</b>&nbsp;:&nbsp;Adult:{" "}
                                  {item?.adults},&nbsp;
                                  {item?.children !== "0" && (
                                    <>Child: {item?.children},</>
                                  )}
                                  &nbsp;
                                  {item?.infants !== "0" && (
                                    <>Infant: {item?.infants}</>
                                  )}
                                </span>
                              </div>
                              {/* <div className="d-flex justify-content-end mt-2">
                                <div
                                  className="book_now"
                                  onClick={() => {
                                    setShow(true);
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn badge-pill btn-outline-primary btn-sm"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div> */}
                              <Modal
                                centered
                                size="md"
                                show={show}
                                onHide={handleClose}
                                className="logout_conf_popup"
                              >
                                <Modal.Body className="px-4 py-3 text-center">
                                  <h4 className="mb-3">Are You Sure Delete?</h4>
                                  <Button
                                    variant="primary"
                                    className="mr-2 px-5 py-2"
                                    onClick={() => {
                                      this.update(item._id);
                                    }}
                                  >
                                    YES
                                  </Button>
                                  <Button
                                    variant="primary"
                                    className="mr-2 px-5 py-2"
                                    onClick={() => setShow(false)}
                                  >
                                    NO
                                  </Button>
                                </Modal.Body>
                              </Modal>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
                {loader && (
                  <div className="flights_box_section">
                    <div className="p-2 skeleton_bg">
                      <div className="row">
                        <div className="col-3">
                          <Skeleton variant="circle" width={40} height={40} />
                        </div>
                        <div className="col-9">
                          <h3>
                            <Skeleton variant="text" width="100%" />
                          </h3>
                        </div>
                      </div>
                      <p>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                    <div className="p-2 skeleton_bg mt-2">
                      <div className="row">
                        <div className="col-3">
                          <Skeleton variant="circle" width={40} height={40} />
                        </div>
                        <div className="col-9">
                          <h3>
                            <Skeleton variant="text" width="100%" />
                          </h3>
                        </div>
                      </div>
                      <p>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                    <div className="p-2 skeleton_bg mt-2">
                      <div className="row">
                        <div className="col-3">
                          <Skeleton variant="circle" width={40} height={40} />
                        </div>
                        <div className="col-9">
                          <h3>
                            <Skeleton variant="text" width="100%" />
                          </h3>
                        </div>
                      </div>
                      <p>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                    <div className="p-2 skeleton_bg mt-2">
                      <div className="row">
                        <div className="col-3">
                          <Skeleton variant="circle" width={40} height={40} />
                        </div>
                        <div className="col-9">
                          <h3>
                            <Skeleton variant="text" width="100%" />
                          </h3>
                        </div>
                      </div>
                      <p>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                  </div>
                )}
                {!loader && bookingList?.length === 0 && (
                  <div className="d-flex justify-content-center mt-5">
                    No Upcoming Booking
                  </div>
                )}
              </div>
            )}
            {selectTab === "complete" && <Complete />}
            {selectTab === "cancel" && <Cancel />}
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="container-fluid">
            <div className="deals_top_heading_mobile">
              <div className="heading">
                <h5>Your Booking List</h5>
              </div>
              <ul className="list-group list-group-horizontal">
                {tabs?.map((data) => {
                  return (
                    <li
                      className={`list-group-item pointer ${
                        selectTab === data?.value ? "active" : ""
                      }`}
                      onClick={() => {
                        handleTabChange(data?.value);
                      }}
                    >
                      {data?.name}
                    </li>
                  );
                })}
              </ul>
            </div>
            <Link
              to="/dashboard"
              className="btn btn-sm btn-light border mb-2 mt-12"
            >
              Back
            </Link>
            {selectTab === "bookings" && (
              <div className="container-fluid">
                {!loader && bookingList?.length > 0 && (
                  <div className="row">
                    {bookingList?.map((item) => {
                      return (
                        <div className="col-12" key={item.id}>
                          {/* <span className="badge badge-pill badge-primary">
                          Value For Money
                        </span> */}
                          <div className="card shadow px-3 py-2">
                            <div className="d-flex flex-column gap-1">
                              <div className="d-flex align-items-center gap-12p flex-wrap mb-0 border-dashed px-4 py-1 rounded border-secondary">
                                <img
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    objectFit: "contain",
                                    objectPosition: "center",
                                  }}
                                  src={
                                    item?.booking_details?.itineraries?.[0]
                                      ?.segments?.[0]?.airlineImage
                                  }
                                  alt="logo"
                                />
                                <div className="d-flex gap-12p align-items-center">
                                  <div className="d-flex flex-column align-items-end">
                                    <b>
                                      {
                                        item?.booking_details?.itineraries?.[0]
                                          ?.segments?.[0]?.departure
                                          ?.iataCode?.[0]
                                      }
                                    </b>
                                    <p className="mb-0">
                                      {moment(
                                        item?.booking_details?.itineraries?.[0]
                                          ?.segments?.[0]?.departure?.at
                                      ).format("DD/MM/YYYY - h:mm A")}
                                    </p>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <span>
                                      {
                                        item?.booking_details?.itineraries?.[0]
                                          ?.duration
                                      }
                                    </span>
                                    <img
                                      style={{
                                        width: "100%",
                                        maxWidth: "220px",
                                      }}
                                      src={from_to}
                                      alt=""
                                    />
                                    <p className="mb-0 text-secondary fs-12p">
                                      {item?.booking_details?.itineraries?.[0]
                                        ?.segments?.length === 1 ? (
                                        "Direct"
                                      ) : (
                                        <span className="pointer">
                                          {item?.booking_details
                                            ?.itineraries?.[0]?.segments
                                            ?.length - 1}
                                          &nbsp;Stop
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                  <div className="d-flex flex-column align-items-start">
                                    <b>
                                      {
                                        item?.booking_details?.itineraries?.[0]
                                          ?.segments[
                                          item?.booking_details
                                            ?.itineraries?.[0]?.segments
                                            ?.length - 1
                                        ]?.arrival?.iataCode?.[0]
                                      }
                                    </b>
                                    <p className="mb-0">
                                      {moment(
                                        item?.booking_details?.itineraries?.[0]
                                          ?.segments[
                                          item?.booking_details
                                            ?.itineraries?.[0]?.segments
                                            ?.length - 1
                                        ]?.arrival?.at
                                      ).format("DD/MM/YYYY - h:mm A")}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {item?.wayOfFlight === "round_trip" && (
                                <div className="d-flex gap-12p align-items-center flex-wrap mb-0 border-dashed px-4 py-1 rounded border-secondary">
                                  <img
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      objectFit: "contain",
                                      objectPosition: "center",
                                    }}
                                    src={
                                      item?.booking_details?.itineraries?.[1]
                                        ?.segments?.[0]?.airlineImage
                                    }
                                    alt="logo"
                                  />
                                  <div className="d-flex gap-12p align-items-center">
                                    <div className="d-flex flex-column align-items-end">
                                      <b>
                                        {
                                          item?.booking_details
                                            ?.itineraries?.[1]?.segments?.[0]
                                            ?.departure?.iataCode?.[0]
                                        }
                                      </b>
                                      <p className="mb-0">
                                        {moment(
                                          item?.booking_details
                                            ?.itineraries?.[1]?.segments?.[0]
                                            ?.departure?.at
                                        ).format("DD/MM/YYYY - h:mm A")}
                                      </p>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <span>
                                        {
                                          item?.booking_details
                                            ?.itineraries?.[1]?.duration
                                        }
                                      </span>
                                      <img
                                        style={{
                                          width: "100%",
                                          maxWidth: "220px",
                                        }}
                                        src={from_to}
                                        alt=""
                                      />
                                      <p className="mb-0 text-secondary fs-12p">
                                        {item?.booking_details?.itineraries?.[1]
                                          ?.segments?.length === 1 ? (
                                          "Direct"
                                        ) : (
                                          <span className="pointer">
                                            {item?.booking_details
                                              ?.itineraries?.[1]?.segments
                                              ?.length - 1}
                                            &nbsp;Stop
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                    <div className="d-flex flex-column align-items-start">
                                      <b>
                                        {
                                          item?.booking_details
                                            ?.itineraries?.[1]?.segments[
                                            item?.booking_details
                                              ?.itineraries?.[0]?.segments
                                              ?.length - 1
                                          ]?.arrival?.iataCode?.[0]
                                        }
                                      </b>
                                      <p className="mb-0">
                                        {moment(
                                          item?.booking_details
                                            ?.itineraries?.[1]?.segments[
                                            item?.booking_details
                                              ?.itineraries?.[1]?.segments
                                              ?.length - 1
                                          ]?.arrival?.at
                                        ).format("DD/MM/YYYY - h:mm A")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="row mt-3 px-1">
                              <div className="col-lg-4 col-md-6">
                                <span className="d-flex align-items-center mb-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#ffa500"
                                    class="bi bi-check-square-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                  </svg>
                                  &nbsp;<b>Class</b>&nbsp;:&nbsp;
                                  {item?.travelClass}
                                </span>
                              </div>
                              <div className="col-lg-4 col-md-6">
                                <span className="d-flex align-items-center mb-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#ffa500"
                                    class="bi bi-airplane-engines-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.35 4.35 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0" />
                                  </svg>
                                  &nbsp;<b>Trip type</b>&nbsp;:&nbsp;
                                  {item?.wayOfFlight === "one_way"
                                    ? "One Way"
                                    : "Round Trip"}
                                </span>
                              </div>
                              <div className="col-lg-4 col-md-6">
                                <span className="d-flex align-items-center mb-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#ffa500"
                                    class="bi bi-check-square-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                  </svg>
                                  &nbsp;<b>Travellers</b>&nbsp;:&nbsp;Adult:{" "}
                                  {item?.adults},&nbsp;
                                  {item?.children !== "0" && (
                                    <>Child: {item?.children},</>
                                  )}
                                  &nbsp;
                                  {item?.infants !== "0" && (
                                    <>Infant: {item?.infants}</>
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end mt-2">
                              <div
                                className="book_now"
                                onClick={() => {
                                  setShow(true);
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn badge-pill btn-outline-primary btn-sm"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                            <Modal
                              centered
                              size="md"
                              show={show}
                              onHide={handleClose}
                              className="logout_conf_popup"
                            >
                              <Modal.Body className="px-4 py-3 text-center">
                                <h4 className="mb-3">Are You Sure Delete?</h4>
                                <Button
                                  variant="primary"
                                  className="mr-2 px-5 py-2"
                                  onClick={() => {
                                    this.update(item.id);
                                  }}
                                >
                                  YES
                                </Button>
                                <Button
                                  variant="primary"
                                  className="mr-2 px-5 py-2"
                                  onClick={() => setShow(false)}
                                >
                                  NO
                                </Button>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {loader && (
                  <div className="flights_box_section">
                    <div className="p-2 skeleton_bg">
                      <div className="row">
                        <div className="col-3">
                          <Skeleton variant="circle" width={40} height={40} />
                        </div>
                        <div className="col-9">
                          <h3>
                            <Skeleton variant="text" width="100%" />
                          </h3>
                        </div>
                      </div>
                      <p>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                    <div className="p-2 skeleton_bg mt-2">
                      <div className="row">
                        <div className="col-3">
                          <Skeleton variant="circle" width={40} height={40} />
                        </div>
                        <div className="col-9">
                          <h3>
                            <Skeleton variant="text" width="100%" />
                          </h3>
                        </div>
                      </div>
                      <p>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                    <div className="p-2 skeleton_bg mt-2">
                      <div className="row">
                        <div className="col-3">
                          <Skeleton variant="circle" width={40} height={40} />
                        </div>
                        <div className="col-9">
                          <h3>
                            <Skeleton variant="text" width="100%" />
                          </h3>
                        </div>
                      </div>
                      <p>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                    <div className="p-2 skeleton_bg mt-2">
                      <div className="row">
                        <div className="col-3">
                          <Skeleton variant="circle" width={40} height={40} />
                        </div>
                        <div className="col-9">
                          <h3>
                            <Skeleton variant="text" width="100%" />
                          </h3>
                        </div>
                      </div>
                      <p>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                  </div>
                )}
                {!loader && bookingList?.length === 0 && (
                  <div className="d-flex justify-content-center mt-5">
                    No Upcoming Booking
                  </div>
                )}
              </div>
            )}
            {selectTab === "complete" && <Complete />}
            {selectTab === "cancel" && <Cancel />}
          </div>
        </>
      )}
    </div>
  );
};

export default Bookings;
