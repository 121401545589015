import React, { useEffect, useRef } from "react";

const OTPBox = (props) => {
  const { otp, otpString, setOtp, setOtpString, title, description, email } =
    props;

  const inputRefs = useRef([]);

  useEffect(() => {
    // Automatically focus on the first input when the component is mounted
    inputRefs.current[0].focus();
  }, []);

  useEffect(() => {
    setOtpString(otp.join(""));
  }, [otp]);

  const handleChange = (index, value) => {
    const newValue = value.replace(/\D/g, "");
    setOtp((prevOTP) => {
      const newOTP = [...prevOTP];
      newOTP[index] = newValue;
      return newOTP;
    });

    // Move to the next input
    if (newValue && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      // Move to the previous input when Backspace is pressed on an empty input
      inputRefs.current[index - 1].focus();
    }
  };
  return (
    <div className="text-center">
      <h4 className="mb-4">{title}</h4>
      <div className="row gx-3">
        <div className="form-group col-lg-12">
          <div className="form-label-group">
            <label>
              {description}&nbsp;<span style={{ color: "green" }}>{email}</span>
            </label>
          </div>
          <div className="otp-box">
            {otp.map((value, index) => (
              <input
                key={index}
                className="otp-input"
                type="text"
                maxLength="1"
                value={value}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(ref) => (inputRefs.current[index] = ref)}
                required
              />
            ))}
          </div>
          <input type="hidden" value={otpString} />
        </div>
      </div>
    </div>
  );
};

export default OTPBox;
